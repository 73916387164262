import { useEffect, useMemo, useState } from "react";

import "aframe";
import dayjs from "dayjs";
import "mind-ar/dist/mindar-image-aframe.prod.js";
const defaultTime = {
  month: "00",
  day: "00",
  hour: "00",
  minute: "00",
  second: "00",
};

const defaultFontColor = "#343434";
const defaultBackgroundColor = "#ffffff";
const zeroPad = (num) => String(Number(num)).padStart(2, "0");

function convertMilliseconds(milliseconds) {
  // Convert milliseconds to seconds
  let totalSeconds = milliseconds / 1000;

  // Calculate months, days, hours, minutes, and seconds
  let months = Math.floor(totalSeconds / (30 * 24 * 3600));
  totalSeconds %= 30 * 24 * 3600;
  let days = Math.floor(totalSeconds / (24 * 3600));
  totalSeconds %= 24 * 3600;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds % 60);

  return `${months} month, ${days} day, ${hours} hour, ${minutes} minute, ${seconds} second`;
}

const Timer = ({ time, ...props }) => {
  const [renderTime, setRenderTime] = useState({
    ...defaultTime,
  });

  useEffect(() => {
    let interval;
    if (time) {
      interval = setInterval(() => {
        const difference = dayjs(time).diff(dayjs(), "millisecond");
        if (difference > 0) {
          const readableFormatArr = convertMilliseconds(difference).split(", ");
          const currentTime = {
            ...defaultTime,
          };

          readableFormatArr.forEach((format) => {
            if (format.includes("month")) {
              let monthValue = format.split("month")?.[0];
              currentTime.month = zeroPad(monthValue);
            } else if (format.includes("day")) {
              let day = format.split("day")?.[0];
              currentTime.day = zeroPad(day);
            } else if (format.includes("hour")) {
              let hour = format.split("hour")?.[0];
              currentTime.hour = zeroPad(hour);
            } else if (format.includes("minute")) {
              let minute = format.split("minute")?.[0];
              currentTime.minute = zeroPad(minute);
            } else if (format.includes("second")) {
              let second = format.split("second")?.[0];
              currentTime.second = zeroPad(second);
            }
            // switch (format) {
            //   case format.includes("month"):
            //     break;
            //   case format.includes("day"):
            //     break;
            //   case format.includes("hour"):
            //     break;
            //   case format.includes("minute"):
            //     break;
            //   case format.includes("second"):
            //     break;
            //   default:
            //     break;
            // }
          });

          setRenderTime({ ...currentTime });
        } else {
          setRenderTime({ ...defaultTime });
          interval && clearInterval(interval);
        }
      }, 1000);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [time]);

  const fontColor = useMemo(() => {
    return props.textColor || defaultFontColor;
  }, [props.textColor]);

  const backgroundColor = useMemo(() => {
    return props.backgroundColor || defaultBackgroundColor;
  }, [props.backgroundColor]);

  return (
    <>
      <a-entity
        position={props.position}
        scale={props.scale}
        rotation={props.rotation}
        material="opacity: 0.5; transparent: true"
        color="#576fd0"
      >
        <a-text
          value={props.title}
          scale="0.3 0.3 0.3"
          position="-0.6 0.2 0"
          color={fontColor}
        ></a-text>
        {/* Months */}
        <a-plane
          color={backgroundColor}
          material="opacity: 0.5; transparent: true"
          scale="0.2 0.2 0.2"
          position="-0.5 0 0"
        >
          <a-text
            color={fontColor}
            value={renderTime.month}
            scale="2 2 2"
            position="-0.3 0.2 0"
          ></a-text>
          <a-text
            color={fontColor}
            value="months"
            scale="0.9 0.9 0.9"
            position="-0.3 -0.25 0"
          ></a-text>
        </a-plane>

        {/* Days */}

        <a-plane
          color={backgroundColor}
          material="opacity: 0.5; transparent: true"
          scale="0.2 0.2 0.2"
          position="-0.25 0 0"
        >
          <a-text
            color={fontColor}
            value={renderTime.day}
            scale="2 2 2"
            position="-0.3 0.2 0"
          ></a-text>
          <a-text
            color={fontColor}
            value="days"
            scale="0.9 0.9 0.9"
            position="-0.2 -0.25 0"
          ></a-text>
        </a-plane>

        {/* Hours */}
        <a-plane
          color={backgroundColor}
          material="opacity: 0.5; transparent: true"
          scale="0.2 0.2 0.2"
          position="0 0 0"
        >
          <a-text
            color={fontColor}
            value={renderTime.hour}
            scale="2 2 2"
            position="-0.3 0.2 0"
          ></a-text>
          <a-text
            color={fontColor}
            value="hours"
            scale="0.9 0.9 0.9"
            position="-0.3 -0.25 0"
          ></a-text>
        </a-plane>

        {/* Minutes */}
        <a-plane
          color={backgroundColor}
          material="opacity: 0.5; transparent: true"
          scale="0.2 0.2 0.2"
          position="0.25 0 0"
        >
          <a-text
            color={fontColor}
            value={renderTime.minute}
            scale="2 2 2"
            position="-0.3 0.2 0"
          ></a-text>
          <a-text
            color={fontColor}
            value="minutes"
            scale="0.9 0.9 0.9"
            position="-0.4 -0.25 0"
          ></a-text>
        </a-plane>

        {/* Seconds */}
        <a-plane
          color={backgroundColor}
          material="opacity: 0.5; transparent: true"
          scale="0.2 0.2 0.2"
          position="0.5 0 0"
        >
          <a-text
            color={fontColor}
            value={renderTime.second}
            scale="2 2 2"
            position="-0.3 0.2 0"
          ></a-text>
          <a-text
            color={fontColor}
            value="seconds"
            scale="0.9 0.9 0.9"
            position="-0.4 -0.25 0"
          ></a-text>
        </a-plane>
      </a-entity>

      {/* <a-entity
        {...props}
        // text={`value: ${"0c0"}; ${props?.text}`}
        // material="color: red; src: https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/insta-logo.png"
      >
      </a-entity> */}
      {/* <a-plane {...props} src={`#${props.id}`}></a-plane> */}
    </>
  );
};

export default Timer;
