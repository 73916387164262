import "./App.css";
import { Route, Routes } from "react-router-dom";
import ScannerPage from "./pages/ScannerPage/ScannerPage";
import OrderList from "./pages/OrderList/OrderList";
import AdminPage from "./pages/Admin/AdminPage";

import ConstructionImage from "./assets/under-construction.png";
import InstaImage from "./assets/insta-logo.png";
import ScannerTestPage from "./pages/ScannerPage/ScannerTestPage";
import MarkerlessTestScannerPage from "./pages/ScannerPage/MarkerlessTestScannerPage";
import MarkerlessScannerPage from "./pages/ScannerPage/MarkerlessScannerPage";
import CountPage from "./pages/CountPage/CountPage";
import { instaDMLink } from "./utils/urls";
// import MakerlessScannerPageV2 from "./pages/MakerlessScannerPage/MakerlessScannerPageV2";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          Component={() => {
            return (
              <div style={{ padding: 20 }}>
                <img
                  src={ConstructionImage}
                  alt=""
                  style={{ width: "100%", maxWidth: 500 }}
                />
                <div style={{ fontSize: 18 }}>
                  This page is under construction please contact us via
                  <div style={{ marginTop: 16 }}>
                    <a href={instaDMLink} rel="noreferrer" target="_blank">
                      <img height={35} src={InstaImage} alt="Instagram page" />
                    </a>
                  </div>
                </div>
              </div>
            );
          }}
        />
        <Route path="/scanner/:urlId" Component={ScannerPage} />
        <Route path="/arTestingScanner/:urlId" Component={ScannerTestPage} />
        <Route
          path="markerlessTestScanner/:urlId"
          Component={MarkerlessTestScannerPage}
        />
        <Route path="mlscanner/:urlId" Component={MarkerlessScannerPage} />
        <Route path="count/:urlId" Component={CountPage} />
        {/* <Route path="test" Component={MakerlessScannerPageV2} /> */}
        <Route path="topsecretadmin" Component={AdminPage}>
          <Route path="" Component={OrderList} />
          <Route path="orders" Component={OrderList} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
