/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "aframe";
import "mind-ar/dist/mindar-image-aframe.prod.js";
import "aframe-chromakey-material";
import PasswordConfirmationModal from "../../components/PasswordConfirmationModal/PasswordConfirmationModal";
import { Button, Row, Steps, Typography, message } from "antd";
import PasswordResetModal from "../../components/PasswordResetModal/PasswordResetModal";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../utils/localstorage-value";
import dayjs from "dayjs";
import Timer from "./components/Timer";
import "./scanner-page.css";
import { useNavigate } from "react-router-dom";

// const domain = "https://192.168.1.9:3011";
const domain = "https://rinivishar.com";
const ScannerTestPage = () => {
  const params = useParams();
  const sceneRef = useRef(null);
  const [passwordModalOpened, setPasswordModalOpened] = useState(false);
  const [passwordResetModalOpened, setPasswordResetModalOpened] =
    useState(false);
  const [error, setError] = useState(undefined);
  const [details, setDetails] = useState();
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [filterBeta, setFilterBeta] = useState(0.001);
  const [started, setStarted] = useState(false);
  const navigate = useNavigate();

  const getProductDetails = async () => {
    const res = await axios
      .get(`${domain}/api/arContent/getDetails/${params.urlId}`)
      .catch((r) => {
        if (typeof r.response?.data?.message === "string") {
          const message = r.response.data.message.toLowerCase();
          if (message.includes("invalid id")) {
            setError("Invalid URL");
          } else {
            setError(r.response?.data?.message);
          }
        }
      });
    const data = res?.data?.data;
    setDetails({
      ...res?.data?.data,
      template: [
        // {
        //   type: "video",
        //   position: "0.25 0.75 0",

        //   width: "0.5",
        //   height: "1",
        //   videoHeight: 1920,
        //   videoWidth: 1080,
        //   // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/demo-projects/people-walking.mp4",
        //   // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/amrish-app-speaking.webm",
        //   src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/amrish-appa-speaking.mp4",
        //   // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/output.webm",
        //   // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/test.mp4",
        //   // src: 'https://rinivish-ar.s3.ap-south-1.amazonaws.com/demo-projects/Untitled+video+-+Made+with+Clipchamp.mp4',
        //   // width: "1",
        //   // height: "0.8",
        //   // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/demo-projects/Original+_+202403082203+_+pexels-anthony-shkraba-production-8135465+(1080p).mp4",
        //   id: "hello",
        //   rotation: "90 0 0",
        //   chromaKeyColor: "0.28 0.99 0.19",
        // },
        // {
        //   type: "video",
        //   position: "-0.25 0 0",

        //   width: "0.5",
        //   height: "1",
        //   videoHeight: 1920,
        //   videoWidth: 1080,
        //   src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/demo-projects/people-walking.mp4",
        //   id: "people-walking",
        //   rotation: "0 0 0",
        //   openLink: "https://www.instagram.com/rinivishar/",
        // },
        {
          type: "timer",
          time: 1717929000000,
          position: "0 -0.2 0.0005",
          rotation: "0 0 0",
          scale: "0.75 0.75 0.75",
          title: "Countdown to our happily ever after :)",
          // textColor: "red",
          // backgroundColor: "blue",
          // text: "color: red",
        },
        // {
        //   type: "video",
        //   position: "0 -1 0",
        //   width: "1",
        //   height: "0.8",
        //   src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/man-saying-wow.mp4",
        //   id: "hello2",
        //   rotation: "0 0 0",
        // },rinivishar
        // {
        //   type: "image",
        //   position: "-0.3 -0.5 0",
        //   rotation: "90 0 0",
        //   src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/demo-projects/26678.png",
        //   width: "0.1",
        //   height: "0.1",
        //   id: "timer-border",
        //   animation:
        //     "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
        // },
        // {
        //   type: "video-image",
        //   position: "-0.3 -0.5 0.0005",
        //   rotation: "0 0 0",
        //   src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/insta-logo.mp4",
        //   width: "0.1",
        //   height: "0.1",
        //   id: "insta",
        //   openLink: "https://www.instagram.com/rinivishar/",
        //   animation:
        //     "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
        // },

        {
          type: "video-image",
          position: "-0.2 -0.5 0.0005",
          rotation: "0 0 0",
          src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/location-icon.mp4",
          width: "0.1",
          height: "0.1",
          id: "location",
          openLink: "https://maps.app.goo.gl/CB9ycv9xXYjL6c3m6",
          animation:
            "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
        },
        {
          type: "video-image",
          position: "0 -0.5 0.0005",
          rotation: "0 0 0",
          src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/call-icon.mp4",
          width: "0.1",
          height: "0.1",
          id: "mobileNumber",
          openLink: "tel:+917483697681",
          animation:
            "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
        },
        {
          type: "video-image",
          position: "0.2 -0.5 0.0005",
          rotation: "0 0 0",
          src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/whatsapp-logo.mp4",
          width: "0.1",
          height: "0.1",
          id: "whatsapp",
          openLink: "https://wa.me/917483697681?text=Congrats",
          animation:
            "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
        },
        // {
        //   type: "video-image",
        //   position: "-0.5 1 0",
        //   width: "1",
        //   height: "1",
        //   src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/save+the+date-amrish.mp4",
        //   id: "save-the-date",
        //   rotation: "90 0 0",
        //   chromaKeyColor: "0.28 0.99 0.19",
        // },
        {
          type: "video-image",
          position: "0 -0.1 0.5",
          width: "1",
          height: "1.1",
          src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/default-template/christian/c-1001.mp4",
          id: "cartoon-template",
          rotation: "90 0 0",
          chromaKeyColor: "0.28 0.99 0.19",
          scale: "0.55 0.55 0.55",
        },
        {
          type: "text",
          value: "Abraham Adip\nweds\n Priyanka",
          align: "center",
          font: "https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/dancingscript/DancingScript-Bold.json",
          scale: "0.4 0.4 0.4",
          color: "red",
          position: "0 0.4 0.8",
          rotation: "90 0 0",
        },
        {
          type: "text",
          value: "9th June 2024, 04:00PM",
          align: "center",
          font: "https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/dancingscript/DancingScript-Bold.json",
          scale: "0.2 0.2 0.2",
          color: "red",
          position: "0 0 0.8",
          rotation: "90 0 0",
        },
        // {
        //   type: "text",
        //   value: "Amrish \n weds \n Monika",
        //   align: "center",
        //   font: "https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/dancingscript/DancingScript-Bold.json",
        //   scale: "0.5 0.5 0.5",
        //   color: "red",
        //   position: "0.55 0 0.50",
        //   rotation: "90 0 0",
        // },
      ],
      referenceImageUrl:
        // "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/sample-pratham/IMG-20240425-WA0002~2.jpg",
        // "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/sample-pratham/flower-front.jpg",
        // "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/amrish-inivitation-first-page.jpg",
        "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/pratham-customer-sample/abraham-wed-priyanka/abraham-wed-priyanka.png",
      mindFileUrl:
        // "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/sample-pratham/targets-flower.mind",
        // "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/sample-pratham/targets+(1).mind",
        // "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/sample-pratham/targets.mind",
        // "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/amrish-invitation-target.mind",
        "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/pratham-customer-sample/nagaraj-weds-preethi/direct-qr-cod.mind",
      defaultMarker: "markerless",
      markerlessTemplate: [
				{
					"type": "timer",
					"time": 1719241153000,
					"position": "0 -0.2 0.0005",
					"rotation": "0 0 0",
					"scale": "0.75 0.75 0.75",
					"title": "Countdown to our happily ever after :)"
				},
				{
					"type": "video-image",
					"position": "-0.2 -0.5 0.0005",
					"rotation": "0 0 0",
					"src": "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/location-icon.mp4",
					"width": "0.1",
					"height": "0.1",
					"id": "location",
					"openLink": "https://maps.app.goo.gl/N8F2oWK6ZDNPS8Fk9",
					"animation": "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
				},
				{
					"type": "video-image",
					"position": "0 -0.5 0.0005",
					"rotation": "0 0 0",
					"src": "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/call-icon.mp4",
					"width": "0.1",
					"height": "0.1",
					"id": "mobileNumber",
					"openLink": "tel:+918453615560",
					"animation": "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
				},
				{
					"type": "video-image",
					"position": "0.2 -0.5 0.0005",
					"rotation": "0 0 0",
					"src": "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/whatsapp-logo.mp4",
					"width": "0.1",
					"height": "0.1",
					"id": "whatsapp",
					"openLink": "https://wa.me/918453615560?text=Congrats",
					"animation": "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
				},
				{
					"type": "video-image",
					"position": "0 -0.1 0.5",
					"width": "1",
					"height": "1.1",
					"src": "https://rinivish-ar.s3.ap-south-1.amazonaws.com/default-template/muslim/m-1004.mp4",
					"id": "cartoon-template",
					"rotation": "90 0 0",
					"chromaKeyColor": "0.28 0.99 0.19",
					"scale": "0.55 0.55 0.55"
				},
				{
					"type": "text",
					"value": "Mahammad Musthafa\nweds\n Shifana",
					"align": "center",
					"font": "https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/dancingscript/DancingScript-Bold.json",
					"scale": "0.4 0.4 0.4",
					"color": "red",
					"position": "0 0.4 0.85",
					"rotation": "90 0 0"
				},
				{
					"type": "text",
					"value": "12th May 2024, 11:00AM",
					"align": "center",
					"font": "https://raw.githubusercontent.com/etiennepinchon/aframe-fonts/master/fonts/dancingscript/DancingScript-Bold.json",
					"scale": "0.2 0.2 0.2",
					"color": "red",
					"position": "0 0 0.8",
					"rotation": "90 0 0"
				}
			]
    });

    console.log(data.defaultMarker, data.defaultMarker === "markerless")
    if (data.defaultMarker === "markerless") {
      navigate("/mlScanner/" + params.urlId);
    }

    if (data) {
      setTimeout(() => {
        setFilterBeta(0.001);
      }, 1000);
      const lastVisited = getLocalStorageValue("lastVisited");

      if (!lastVisited || dayjs().diff(dayjs(lastVisited), "minute") >= 5) {
        updateTotalVisit();
        setLocalStorageValue("lastVisited", dayjs().toISOString());
      }
      setTimeout(() => {
        setCountdown(10);
      }, 5000);
      if (data?.hasPassword) {
        if (data?.passwordResetted) {
          const passwordDontAsk = getLocalStorageValue("passwordDontAsk");
          if (passwordDontAsk === "true") {
            setPasswordSuccess(true);
          } else {
            setPasswordModalOpened(true);
          }
        } else {
          setPasswordResetModalOpened(true);
        }
      } else {
        setPasswordSuccess(true);
      }
    }
  };

  const verifyPassword = async (values) => {
    const res = await axios
      .post(`${domain}/api/arContent/verifyPassword`, {
        ...values,
        urlId: params.urlId,
      })
      .catch((r) => {
        message.error({
          content: "Password mismatch",
        });
        console.log(r);
      });
    if (res) {
      if (values.dontAsk) {
        setLocalStorageValue("passwordDontAsk", "true");
      }

      setPasswordModalOpened(false);
      setPasswordSuccess(true);
    }
  };

  const resetPassword = async (values) => {
    const res = await axios
      .post(`${domain}/api/arContent/resetPassword`, {
        ...values,
        urlId: params.urlId,
      })
      .catch((r) => {
        const errorMessage = r.response?.data?.message;
        if (typeof errorMessage === "string") {
          message.error({
            content: errorMessage.includes("Password mismatch")
              ? "Please enter valid password provided by us"
              : errorMessage,
          });
        }

        console.log(r);
      });
    if (res) {
      setPasswordResetModalOpened(false);
      setPasswordSuccess(true);
    }
  };

  const updateTotalVisit = async () => {
    axios
      .post(`${domain}/api/arContent/updateTotalVisit`, {
        urlId: params.urlId,
      })
      .catch((r) => {});
  };

  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    // setLocalStorageValue("lastVisited", dayjs().toISOString());
    // const lastVisited = getLocalStorageValue("lastVisited");
    // console.log(
    //   lastVisited,
    //   dayjs().toISOString(),
    //   dayjs().diff(dayjs(null), "minute"),
    //   dayjs().diff(dayjs(null), "minute") > 5
    // );
    getProductDetails();
  }, []);

  useEffect(() => {
    const sceneEl = sceneRef.current;
    if (sceneEl && details && passwordSuccess && started) {
      setTimeout(() => {
        // var sceneEl = document.querySelector("a-scene");

        // sceneEl.addEventListener("loaded", function (evt) {
        //   var newCamera = new THREE.PerspectiveCamera();
        //   newCamera.near = 1;
        //   newCamera.far = 100;
        //   sceneEl.camera = newCamera;
        // });
        const arSystem = sceneEl?.systems?.["mindar-image-system"];
        const target = document.querySelector("#target");
        details.template.forEach((template) => {
          if (
            (template.type === "image" || template.type === "video-image") &&
            template.openLink
          ) {
            const image = document.getElementById(`${template.id}-image`);
            image.onclick = () => {
              window.open(template.openLink, "_blank");
            };
          }
          if (template.type === "video-image") {
            const image = document.getElementById(`${template.id}`);
            if (image) {
              image.play();
            }
          }
        });
        sceneEl.addEventListener("loaded", function () {
          // arSystem = sceneEl.systems["mindar-image-system"];
          // const video = document.getElementById(`imagesss-image`);
          // alert(video);
          // alert(JSON.stringify(video));
          // alert(video.addEventListener);
          // video.addEventListener(
          //   "click",
          //   function () {
          //     alert("hello");
          //   },
          //   false
          // );
          // video.onclick = () => {
          //   window.open("https://www.google.com", "_blank");
          // };
        });

        target.addEventListener("targetFound", () => {
          details.template.forEach((template) => {
            if (template.type === "video") {
              const video = document.querySelector(`#${template.id}`);
              if (video) {
                video?.play();
              }
            }
          });
        });

        target.addEventListener("targetLost", () => {
          details.template.forEach((template) => {
            if (template.type === "video") {
              const video = document.querySelector(`#${template.id}`);
              if (video) {
                video?.pause();
              }
            }
          });
        });

        sceneEl?.addEventListener("renderstart", () => {
          setTimeout(() => {
            try {
              arSystem?.start();
            } catch (error) {}
          }, 1000);
          // alert("start");
          // arSystem?.start(); // start AR
        });
      }, 1);
    }

    return () => {
      try {
        // arSystem.stop && arSystem?.stop();
      } catch (error) {
        console.log(error);
      }

      // const overlays = document.getElementsByClassName("mindar-ui-overlay");
      // Array.from(overlays).forEach((e) => {
      //   e.remove();
      // });
    };
  }, [details, passwordSuccess, started]);

  const entities = useMemo(() => {
    return details?.template?.map((t) => (
      <>
        {t.type === "video" && !t.chromaKeyColor && (
          <a-video {...t} src={`#${t.id}`} key={t.id}></a-video>
        )}
        {t.type === "video" && t.chromaKeyColor && (
          <a-plane
            {...t}
            key={t.id}
            material={`shader: chromakey; src: #${t.id}; color: ${t.chromaKeyColor}`}
          ></a-plane>
        )}
        {t.type === "video-image" && (
          <a-plane
            {...t}
            key={t.id}
            material={`shader: chromakey; src: #${t.id}; color: ${
              t.chromaKeyColor === "no"
                ? undefined
                : t.chromaKeyColor || "0 0 0"
            }`}
            id={`${t.id}-image`}
            class="clickable"
          ></a-plane>
        )}
        {t.type === "image" && (
          <a-image
            {...t}
            src={`#${t.id}`}
            key={t.id}
            id={`${t.id}-image`}
            class="clickable"
          ></a-image>
        )}
        {t.type === "timer" && <Timer {...t} key={t.id} />}
        {t.type === "text" && <a-text {...t} shader="msdf"></a-text>}
      </>
    ));
  }, [details?.template]);

  return (
    <div
      className="scanner-page-container"
      style={{
        height: "100%",
        position: "relative",
        // opacity: !started ? 0 : 1,
        // zIndex: !started ? -10 : undefined,
      }}
    >
      <div id="scanning-overlay" class="hidden">
        <div class="inner">
          <img
            alt=""
            src={details?.referenceImageUrl}
            crossOrigin="anonymous"
          />
          <div class="scanline"></div>
        </div>
      </div>
      {!started && !error && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: 1000,
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexFlow: "column",
          }}
        >
          {params.urlId === "1c221a3e284f19f9acaf7789d1d129" && (
            <div>
              <Typography.Title level={3}>Instructions:</Typography.Title>
              <Steps
                style={{ width: "100%", paddingLeft: "20px" }}
                current={3}
                progressDot={true}
                responsive={true}
                direction="vertical"
                items={[
                  {
                    title: "Click the button below",
                  },
                  {
                    title: "Point your phone at the invitation.",
                  },
                  {
                    title: "Experience the AR invitation.",
                  },
                ]}
              />
            </div>
          )}
          <div>
            <Button
              style={{
                marginBottom: 50,
              }}
              type="primary"
              size="large"
              onClick={() => setStarted(true)}
            >
              Click here to see the magic!
            </Button>
          </div>
        </div>
      )}

      {details && passwordSuccess && started && (
        <a-scene
          ref={sceneRef}
          mindar-image={`uiScanning: ${
            details?.referenceImageUrl ? "#scanning-overlay" : "yes"
          }; imageTargetSrc: ${
            details.mindFileUrl
          }; `}
          color-space="sRGB"
          renderer="colorManagement: true, physicallyCorrectLights"
          xr-mode-ui="enabled: false"
          device-orientation-permission-ui="enabled: false"
        >
          <a-assets>
            {details.template?.map((t) => (
              <>
                {t.type === "video" && (
                  <video
                    key={t.id}
                    id={t.id}
                    loop
                    src={t.src}
                    crossOrigin="anonymous"
                    playsInline
                    width={t.videoWidth}
                    height={t.videoHeight}
                    poster="/green-screen.png"
                  ></video>
                )}
                {t.type === "video-image" && (
                  <video
                    key={t.id}
                    id={t.id}
                    loop
                    src={t.src}
                    crossOrigin="anonymous"
                    playsInline
                    muted
                    // poster="/green-screen.png"
                  ></video>
                )}
                {t.type === "image" && (
                  <img
                    key={t.id}
                    id={t.id}
                    alt=""
                    src={t.src}
                    crossOrigin="anonymous"
                  />
                )}
              </>
            ))}
            {/* <a-asset-item
              id="avatarModel"
              // src="./suzuki_rm-z450_supermoto/scene.gltf"
              src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.2/examples/image-tracking/assets/card-example/softmind/scene.gltf"
            ></a-asset-item> */}
          </a-assets>

          <a-camera
            position="0 0 0"
            look-controls="enabled: false"
            cursor="fuse: false; rayOrigin: mouse;"
            raycaster="far: 10000; objects: .clickable"
          ></a-camera>
          <a-entity mindar-image-target="targetIndex: 0" id="target">
            {entities}
            {/* <a-entity
              gltf-model="#avatarModel"
              position="0 0 0"
              scale=" 0.001 0.001 0.001"
            ></a-entity> */}

            {/* <a-gltf-model
              rotation="0 0 0"
              position="0 0 0"
              scale=" 0.001 0.001 0.001"
              src="#avatarModel"
              // animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
            ></a-gltf-model> */}
          </a-entity>
        </a-scene>
      )}
      {error && (
        <Row
          justify="center"
          align="middle"
          style={{
            fontSize: 30,
            height: "100%",
            // top: "calc(50% - 100px)",
            // left: "calc(50% - 100px)",
            // position: "absolute",
          }}
        >
          {error}.&nbsp;<Link to="/">Click here</Link>&nbsp;to return to
          homepage.
        </Row>
      )}
      <PasswordConfirmationModal
        opened={passwordModalOpened}
        onOk={(values) => {
          verifyPassword(values);
        }}
      />
      <PasswordResetModal
        opened={passwordResetModalOpened}
        onOk={(values) => {
          resetPassword(values);
        }}
      />
    </div>
  );
};
export default ScannerTestPage;
