import { Button, Checkbox, Form, Input, Modal } from "antd";

const PasswordConfirmationModal = ({
  opened,
  onOk,
}: {
  opened: boolean;
  onOk: (formValues: any) => void;
}) => {
  return (
    <Modal
      open={opened}
      footer={false}
      title="Enter password to continue"
      closable={false}
    >
      <Form layout="vertical" onFinish={onOk}>
        <Form.Item
          label="Password"
          name="password"
          style={{ marginBottom: 10 }}
          rules={[{ required: true, message: "Please enter the password" }]}
        >
          <Input placeholder="Please enter password" />
        </Form.Item>
        <Form.Item
          name="dontAsk"
          valuePropName="checked"
          style={{ marginBottom: 5 }}
        >
          <Checkbox>Don't ask again</Checkbox>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Ok
        </Button>
      </Form>
    </Modal>
  );
};

export default PasswordConfirmationModal;
