import { Button, Form, Input } from "antd";

const CreateOrderDrawer = () => {
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={(values) => {
          const formData = new FormData();

          Object.entries(values).forEach(([key, value]: any) => {
            formData.set(key, value);
          });

          console.log(formData);
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: "Please enter Address" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="mobileNumber"
          label="Mobile number"
          rules={[{ required: true, message: "Please enter mobile number" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="productName"
          label="Product name"
          rules={[{ required: true, message: "Please enter product name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="refrenceImage"
          label="Reference image"
          valuePropName="files"
          getValueFromEvent={(e) => e.target.files?.[0]}
        >
          <Input type="file" accept="image/*" />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Create order
        </Button>
      </Form>
    </div>
  );
};

export default CreateOrderDrawer;
