export const getLocalStorageValue = (key: string) => {
  const encodeKey = btoa(key);
  const encodeValue = localStorage.getItem(encodeKey);
  if (!encodeValue) {
    return encodeValue;
  }
  let decode1 = atob(encodeValue);
  let decode2 = atob(decode1);
  return decode2;
};
export const setLocalStorageValue = (key: string, value: string) => {
  const encodeKey = btoa(key);
  let encode1 = btoa(value);
  let encode2 = btoa(encode1);
  localStorage.setItem(encodeKey, encode2);
};
