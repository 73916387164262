import { Button, Form, Input, Modal } from "antd";

const PasswordResetModal = ({
  opened,
  onOk,
}: {
  opened: boolean;
  onOk: (formValues: any) => void;
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={opened}
      footer={false}
      title="Enter password to continue"
      closable={false}
    >
      <Form layout="vertical" onFinish={onOk} form={form}>
        <Form.Item
          label="Password provided by us"
          name="systemPassword"
          rules={[{ required: true, message: "Please enter the password" }]}
        >
          <Input.Password placeholder="Please enter password by us" />
        </Form.Item>
        <Form.Item
          label="New password"
          name="password"
          rules={[{ required: true, message: "Please enter the password" }]}
        >
          <Input.Password placeholder="Please enter password" />
        </Form.Item>
        <Form.Item
          label="Confirm new password"
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            { required: true, message: "Please enter the password again" },
            {
              validator: (rule, value) => {
                if (!value) {
                  return Promise.resolve();
                }

                const password = form.getFieldsValue().password;
                if (password !== value) {
                  return Promise.reject("Password not matching");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password placeholder="Please enter password" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Ok
        </Button>
      </Form>
    </Modal>
  );
};

export default PasswordResetModal;
