import "aframe";
import { useEffect, useMemo, useRef, useState } from "react";
import "aframe-chromakey-material";
import Timer from "./components/Timer";
import { Row, message } from "antd";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../utils/localstorage-value";
import dayjs from "dayjs";
import PasswordConfirmationModal from "../../components/PasswordConfirmationModal/PasswordConfirmationModal";
import PasswordResetModal from "../../components/PasswordResetModal/PasswordResetModal";

// const domain = "https://192.168.1.9:3011";
const domain = "https://rinivishar.com";
const MarkerlessScannerPage = () => {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState();
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [passwordModalOpened, setPasswordModalOpened] = useState(false);
  const [passwordResetModalOpened, setPasswordResetModalOpened] =
    useState(false);
  const [error, setError] = useState(undefined);
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  var constraints = {
    audio: false,
    video: {
      facingMode: "environment",
    },
  };
  function cameraStart() {
    if (passwordSuccess) {
      const cameraView = document.querySelector("#webcam");
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          cameraView.srcObject = stream;
          setHasCameraPermission(true);
        })
        .catch(function (error) {
          console.error("Oops. Something is broken.", error);
        });
    }
  }
  const isaframeCompomentRegistered = useRef(false);

  const template = [
    {
      type: "video",
      position: "0.1 -0.22 -0.05",
      scale: "0.4 0.4 1",
      width: "0.6",
      height: "1",
      videoHeight: 1920,
      videoWidth: 1080,
      // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/demo-projects/people-walking.mp4",
      // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/amrish-app-speaking.webm",
      src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/amrish-appa-speaking.mp4",
      // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/output.webm",
      // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/test.mp4",
      // src: 'https://rinivish-ar.s3.ap-south-1.amazonaws.com/demo-projects/Untitled+video+-+Made+with+Clipchamp.mp4',
      // width: "1",
      // height: "0.8",
      // src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/demo-projects/Original+_+202403082203+_+pexels-anthony-shkraba-production-8135465+(1080p).mp4",
      id: "hello",
      rotation: "0 0 0",
      chromaKeyColor: "0.28 0.99 0.19",
    },

    {
      type: "timer",
      time: 1717909200000,
      position: "0 -0.52 0",
      rotation: "-90 0 0",
      scale: "0.5 0.5 0.5",
      title: "Countdown to our happily ever after :)",
      // textColor: "red",
      // backgroundColor: "blue",
      // text: "color: red",
    },

    {
      type: "video-image",
      position: "-0.2 -0.5 0.2",
      rotation: "-90 0 0",
      src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/insta-logo.mp4",
      width: "0.07",
      height: "0.07",
      id: "insta",
      openLink: "https://www.instagram.com/rinivishar/",
      animation:
        "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
    },

    {
      type: "video-image",
      position: "-0.05 -0.5 0.2",
      rotation: "-90 0 0",
      src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/location-icon.mp4",
      width: "0.07",
      height: "0.07",
      id: "location",
      openLink: "https://maps.app.goo.gl/api59NbLFbBAaWGZA",
      animation:
        "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
    },
    {
      type: "video-image",
      position: "0.1 -0.5 0.2",
      rotation: "-90 0 0",
      src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/call-icon.mp4",
      width: "0.07",
      height: "0.07",
      id: "mobileNumber",
      openLink: "tel:+919092645666",
      animation:
        "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
    },
    {
      type: "video-image",
      position: "0.25 -0.5 0.2",
      rotation: "-90 0 0",
      src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/whatsapp-logo.mp4",
      width: "0.07",
      height: "0.07",
      id: "whatsapp",
      openLink: "https://wa.me/919092645666?text=Congrats",
      animation:
        "property: scale; to: 1.2 1.2 1.2; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate",
    },
    {
      type: "video-image",
      position: "-0.3 -0.25 -0.05",
      // position: "-0.5 1 0",
      scale: "0.35 0.35 1",

      width: "1",
      height: "1",
      src: "https://rinivish-ar.s3.ap-south-1.amazonaws.com/project/save+the+date-amrish.mp4",
      id: "save-the-date",
      rotation: "0 0 0",
      chromaKeyColor: "0.28 0.99 0.19",
    },
  ];
  const registerComponent = () => {
    if (!isaframeCompomentRegistered.current) {
      // eslint-disable-next-line no-undef
      AFRAME.registerComponent("pinch-controls", {
        init: function () {
          // moveCamera uses variables from 'this' scope
          this.moveCamera.bind(this);
          // we'll use this to get the 'pinch direction'
          this.distance = 0;
          // we'll keep here the camera's current direction
          // eslint-disable-next-line no-undef
          this.direction = new THREE.Vector3();
          // camera entity reference
          this.camera = document.querySelector("[camera]");
          // listeners
          document.body.addEventListener(
            "touchstart",
            (event) => {
              // we're interested only in two - finger pinches
              if (event.touches.length !== 2) return 0;
              // calculate the distance
              this.distance = this.calculatePinchDistance(event);
              // we don't want the touch to rotate the camera around
              this.el.setAttribute("look-controls", "touchEnabled", false);
            },
            false
          );
          document.body.addEventListener(
            "touchend",
            (event) => {
              // when the pinch ends - restore the look-controls
              if (event.touches.length !== 1)
                this.el.setAttribute("look-controls", "touchEnabled", true);
            },
            false
          );
          document.body.addEventListener(
            "touchmove",
            (event) => {
              // we're interested only in two - finger pinches
              if (event.touches.length !== 2) return 0;
              // compare the distances to determine which direction should we move
              var distance = this.calculatePinchDistance(event);
              let speed = distance < this.distance ? -0.005 : 0.005;
              this.moveCamera(speed);
              // keep the distance for the next callback
              this.distance = distance;
            },
            false
          );
        },
        calculatePinchDistance(event) {
          var dx = event.touches[0].pageX - event.touches[1].pageX;
          var dy = event.touches[0].pageY - event.touches[1].pageY;
          return Math.sqrt(dx * dx + dy * dy);
        },
        moveCamera: function (speed) {
          // get the camera direction, and multiply it by the desired 'speed'
          this.el.sceneEl.camera.getWorldDirection(this.direction);
          this.direction.multiplyScalar(speed);
          // apply the change to the actual position
          var pos = this.el.getAttribute("position");
          pos.add(this.direction);
          this.el.setAttribute("position", pos);
        },
      });
    }

    isaframeCompomentRegistered.current = true;
  };

  useEffect(() => {
    if (passwordSuccess) {
      cameraStart();
    }

    return () => {};
  }, [passwordSuccess]);

  useEffect(() => {
    registerComponent();
    getProductDetails();
  }, []);

  useEffect(() => {
    if (details && passwordSuccess) {
      const sceneEl = document.querySelector("a-scene");
      sceneEl.addEventListener("click", (e) => {
        setShow(true);
      });
    }
  }, [details, passwordSuccess]);
  useEffect(() => {
    if (show === true) {
      updateTotalVisit();
    }
  }, [show]);

  useEffect(() => {
    if (show === true && details?.markerLessTemplate) {
      details?.markerLessTemplate.forEach((t) => {
        if ((t.type === "image" || t.type === "video-image") && t.openLink) {
          const image = document.getElementById(`${t.id}-image`);
          image.onclick = () => {
            window.open(t.openLink, "_blank");
          };
        }
        if (t.type === "video" || t.type === "video-image") {
          const video = document.querySelector(`#${t.id}`);
          if (video) {
            video?.play();
          }
        }
      });
    }
  }, [show, details?.markerLessTemplate]);

  const entities = useMemo(() => {
    return details?.markerLessTemplate?.map((t) => (
      <>
        {t.type === "video" && !t.chromaKeyColor && (
          <a-video {...t} src={`#${t.id}`} key={t.id}></a-video>
        )}
        {t.type === "video" && t.chromaKeyColor && (
          <a-plane
            {...t}
            key={t.id}
            material={`shader: chromakey; src: #${t.id}; color: ${t.chromaKeyColor}`}
          ></a-plane>
        )}
        {t.type === "video-image" && (
          <a-plane
            {...t}
            key={t.id}
            material={`shader: chromakey; src: #${t.id}; color: ${
              t.chromaKeyColor === "no"
                ? undefined
                : t.chromaKeyColor || "0 0 0"
            }`}
            id={`${t.id}-image`}
            class="clickable"
          ></a-plane>
        )}
        {t.type === "image" && (
          <a-image
            {...t}
            src={`#${t.id}`}
            key={t.id}
            id={`${t.id}-image`}
            class="clickable"
          ></a-image>
        )}
        {t.type === "timer" && <Timer {...t} key={t.id} />}
        {t.type === "text" && <a-text {...t} shader="msdf"></a-text>}
      </>
    ));
  }, [details?.markerLessTemplate]);

  const getProductDetails = async () => {
    const res = await axios
      .get(`${domain}/api/arContent/getDetails/${params.urlId}`)
      .catch((r) => {
        if (typeof r.response?.data?.message === "string") {
          const message = r.response.data.message.toLowerCase();
          if (message.includes("invalid id")) {
            setError("Invalid URL");
          } else {
            setError(r.response?.data?.message);
          }
        }
      });

    const data = res?.data?.data;
    console.log(data);
    setDetails({
      ...res?.data?.data,
    });
    if (data) {
      const lastVisited = getLocalStorageValue("lastVisited");

      if (!lastVisited || dayjs().diff(dayjs(lastVisited), "minute") >= 5) {
        updateTotalVisit();
        setLocalStorageValue("lastVisited", dayjs().toISOString());
      }

      if (data?.hasPassword) {
        if (data?.passwordResetted) {
          const passwordDontAsk = getLocalStorageValue("passwordDontAsk");
          if (passwordDontAsk === "true") {
            setPasswordSuccess(true);
          } else {
            setPasswordModalOpened(true);
          }
        } else {
          setPasswordResetModalOpened(true);
        }
      } else {
        setPasswordSuccess(true);
      }
    }
  };
  const verifyPassword = async (values) => {
    const res = await axios
      .post(`${domain}/api/arContent/verifyPassword`, {
        ...values,
        urlId: params.urlId,
      })
      .catch((r) => {
        message.error({
          content: "Password mismatch",
        });
      });
    if (res) {
      if (values.dontAsk) {
        setLocalStorageValue("passwordDontAsk", "true");
      }

      setPasswordModalOpened(false);
      setPasswordSuccess(true);
    }
  };

  const resetPassword = async (values) => {
    const res = await axios
      .post(`${domain}/api/arContent/resetPassword`, {
        ...values,
        urlId: params.urlId,
      })
      .catch((r) => {
        const errorMessage = r.response?.data?.message;
        if (typeof errorMessage === "string") {
          message.error({
            content: errorMessage.includes("Password mismatch")
              ? "Please enter valid password provided by us"
              : errorMessage,
          });
        }

        console.log(r);
      });
    if (res) {
      setPasswordResetModalOpened(false);
      setPasswordSuccess(true);
    }
  };

  const updateTotalVisit = async () => {
    axios
      .post(`${domain}/api/arContent/updateTotalVisit`, {
        urlId: params.urlId,
      })
      .catch((r) => {});
  };

  return (
    <div style={{ height: "100%" }}>
      {!error && passwordSuccess && (
        <video id="webcam" autoPlay playsInline muted></video>
      )}

      {details && passwordSuccess && (
        <a-scene
        // device-orientation-permission-ui="enabled: true"
        // cursor="rayOrigin: mouse"
        // raycaster="objects: .clickable"
        // class="clickable"
        // embedded
        // renderer="precision: mediump"
        // background="color: #ECECEC"
        // webxr="optionalFeatures:  hit-test;"
        // ar-hit-test="target:#myobject;"
        >
          <a-assets>
            {details?.markerLessTemplate?.map((t) => (
              <>
                {t.type === "video" && (
                  <video
                    key={t.id}
                    id={t.id}
                    loop
                    src={t.src}
                    crossOrigin="anonymous"
                    playsInline
                    width={t.videoWidth}
                    height={t.videoHeight}
                  ></video>
                )}
                {t.type === "video-image" && (
                  <video
                    key={t.id}
                    id={t.id}
                    loop
                    src={t.src}
                    crossOrigin="anonymous"
                    playsInline
                    muted
                  ></video>
                )}
                {t.type === "image" && (
                  <img
                    key={t.id}
                    id={t.id}
                    alt=""
                    src={t.src}
                    crossOrigin="anonymous"
                  />
                )}
              </>
            ))}
          </a-assets>

          {show && (
            <>
              <a-camera
                pinch-controls
                position="0 0 0"
                cursor="fuse: false; rayOrigin: mouse;"
                raycaster="far: 10000; objects: .clickable"
                look-controls="touchEnabled: false; mouseEnabled: false"
                camera="active: true"
              ></a-camera>
              <a-entity position="0 0 -0.75" rotation="0 0 0">
                {entities}
              </a-entity>
            </>
          )}
        </a-scene>
      )}

      {error && (
        <Row
          justify="center"
          align="middle"
          style={{
            fontSize: 30,
            height: "100%",
            top: "calc(50% - 100px)",
            left: "calc(50% - 100px)",
            position: "absolute",
          }}
        >
          {error}.&nbsp;
          <Link to="https://www.instagram.com/rinivishar/">Click here</Link>
          &nbsp;to contact us
        </Row>
      )}
      {details && passwordSuccess && !show && hasCameraPermission && (
        <>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "200px",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              width={150}
              src="https://rinivish-ar.s3.ap-south-1.amazonaws.com/public/tap-tap.gif"
              alt=""
              crossOrigin="anonymous"
            />
            <div style={{ fontSize: 24, position: "absolute" }}>
              Tap anywhere on the flat surface
            </div>
          </div>
        </>
      )}
      <PasswordConfirmationModal
        opened={passwordModalOpened}
        onOk={(values) => {
          verifyPassword(values);
        }}
      />
      <PasswordResetModal
        opened={passwordResetModalOpened}
        onOk={(values) => {
          resetPassword(values);
        }}
      />
    </div>
  );
};

export default MarkerlessScannerPage;
