import { Alert, Button, Col, Progress, Row, Spin } from "antd";
import "./count-page.scss";
import { RefreshIcon, EyeIcon } from "../../icons/Icons";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { instaDMLink } from "../../utils/urls";

// const domain = "https://192.168.1.9:3011";
const domain = "https://rinivishar.com";
const CountPage = () => {
  const params = useParams();
  const [error, setError] = useState("");
  const [countDetails, setCountDetails] = useState({
    available: 0,
    total: 0,
  });
  const [spinning, setSpinning] = useState(false);
  const getCountDetails = async () => {
    setSpinning(true);
    const res = await axios
      .get(`${domain}/api/arContent/getCount/${params.urlId}`)
      .catch((r) => {
        if (typeof r.response?.data?.message === "string") {
          const message = r.response.data.message.toLowerCase();
          if (message.includes("invalid id")) {
            setError("Invalid URL");
          } else {
            setError(r.response?.data?.message);
          }
        } else {
          setError("Invalid URL");
        }
      });

    setSpinning(false);
    const data = res?.data?.data;

    setCountDetails({
      available: data?.total,
      total: data?.limit,
    });
  };

  useEffect(() => {
    getCountDetails();
  }, []);

  const percentage = useMemo(() => {
    return countDetails.total
      ? parseFloat((countDetails.available / countDetails.total).toFixed(2))
      : 0;
  }, [countDetails]);

  return (
    <Row align="middle" justify="center" className="count-page-container">
      <Spin spinning={spinning} style={{ width: "100%" }}>
        {!error ? (
          <Col>
            <div className="card">
              <Row align="top" justify="space-between">
                <div>
                  <Row align="middle" justify="center">
                    <EyeIcon
                      style={{ marginRight: 10, color: "rgb(124, 123, 137)" }}
                    />
                    Available Views
                  </Row>
                  <div className="count-wrapper">
                    <span className="count">{countDetails.available}/</span>
                    {countDetails.total} views
                  </div>
                </div>

                <Button
                  onClick={getCountDetails}
                  icon={<RefreshIcon style={{ color: "#fff" }} />}
                />
              </Row>
              <Progress percent={percentage} />
            </div>
          </Col>
        ) : (
          <div className="error-card">
            <div className="title">Error</div>
            <div>
              {error}. <Link to={instaDMLink}>Click here</Link> to contact us
            </div>
          </div>
        )}
      </Spin>
    </Row>
  );
};

export default CountPage;
