import { ReactComponent as RefreshSvg } from "../assets/svg/refresh-icon.svg";
import { ReactComponent as EyeSvg } from "../assets/svg/eye-icon.svg";

export const RefreshIcon = (props: any) => {
  return <RefreshSvg {...props} />;
};

export const EyeIcon = (props: any) => {
  return <EyeSvg {...props} />;
};
