import { Button, Drawer, Row, Table } from "antd";
import { useState } from "react";
import CreateOrderDrawer from "./components/CreateOrderDrawer";

const OrderList = () => {
  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Product name",
      dataIndex: "productName",
    },
    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
    },
    {
      title: "Estimated delivery",
      dataIndex: "estimatedDelivery",
    },
    {
      title: "Delivered date",
      dataIndex: "deliveredDate",
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
    },
  ];
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Row justify="end">
        <Button type="primary" onClick={showDrawer}>
          New order
        </Button>
      </Row>
      <Table columns={columns} dataSource={[]} />

      <Drawer
        maskClosable={false}
        title="New order"
        onClose={onClose}
        open={open}
        size="large"
      >
        <CreateOrderDrawer />
      </Drawer>
    </div>
  );
};

export default OrderList;
